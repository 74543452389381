<template>
    <div class="shouhouindex">
        <p class="link" @click="tourl">返回订单列表</p>

        <div class="mainbox">
            <p class="tips"> 请选择售后原因 </p>
            <div class="tuimg flexbox">
                <p @click.stop="changeping(1)">
                    <img src="@/assets/img/choose.png" alt="" v-if="p1 == false" >
                    <img src="@/assets/img/choosedd.png" alt="" v-if="p1">
                    <span>态度恶劣</span>
                </p>
              
                <p @click.stop="changeping(2)">
                    <img src="@/assets/img/choose.png" alt="" v-if="p2 == false" >
                    <img src="@/assets/img/choosedd.png" alt="" v-if="p2">
                    <span>不专业</span>
                </p>

                <p @click.stop="changeping(3)">
                    <img src="@/assets/img/choose.png" alt="" v-if="p3 == false" >
                    <img src="@/assets/img/choosedd.png" alt="" v-if="p3">
                    <span>回答不满意</span>
                </p>

            </div>

            <el-input
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 100}"
                placeholder="请简述售后具体原因"
                v-model="textarea">
            </el-input>

            <div class="btnbox" @click="submit">立即提交</div>
        </div>
    </div>
</template>

<script>
import apiUrl from '@/api/main'
export default {
    components: {},
    data() {
        return {
            pingItem:'',
            p1:false,
            p2:false,
            p3:false,
            textarea:''
        };
    },
    computed: {},
    watch: {},
    methods: {
        tourl() {
            this.$router.go(-1);
        },
        changeping(num){
            for (let i = 1; i< 6; i++) {
               if(i == num){
                this[`p${num}`] = !this[`p${num}`];
                this.pingItem = num;
               }else {
                this[`p${i}`] = false
               }
            }
        },
          // 售后
          submit(){
            if(!this.pingItem){
                this.$message.error('请选择售后原因');
                return;
            }
            if(!this.textarea){
                this.$message.error('请详细描述售后原因');
                return;
            }
            var shouhou = '';
            if(this.pingItem == 1){
                shouhou = '态度恶劣'
            }else if(this.pingItem == 2){
                shouhou = '不专业'
            }else if(this.pingItem == 3){
                shouhou = '回答不满意'
            }
            let data = {
                id: this.$route.query.id,
                shouhuo: shouhou, 
                shouhuojianjie:this.textarea, 
            }
            apiUrl.shouhouorder(data).then((res) => {
                if(res.code == 0){
                    this.$message.success('操作成功');
                    this.$router.go(-1);
                }
               
            });
        }
    },
    created() {},
    mounted() {},
    }
</script>
<style lang='scss'>
.shouhouindex{
    .link{
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #305BFE;
        line-height: 18px;
        margin-bottom: 10px;
        cursor: pointer;
    }
    .mainbox{
        width: 1216px;
        background: #FFFFFF;
        border-radius: 13px;
        padding: 33px 40px 60px 40px;
        .tips{
            font-size: 15px;
            font-weight: 500;
            color: #333333;
        }
        .tuimg{
            margin-top: 23px;
            margin-bottom: 40px;
            p{
                display: flex;
                align-items: center;
                margin-right: 47px;
                cursor: pointer;
                img{
                    width: 18px;
                    height: 18px;
                    margin-right: 13px;
                }
            }
           
        }
     
        .el-textarea__inner{
            width: 570px;
            background-color: #f8f8f8;
            border: 1px solid #f8f8f8;
        }
        
        .btnbox{
            width: 187px;
            height: 43px;
            line-height: 43px;
            border-radius: 30px;
            margin-top: 67px;
        }
    }
}

</style>